<template>
  <v-col cols="4">
    <v-card-text>
      <v-autocomplete
        v-model="model"
        :items="items"
        :loading="isLoading"
        :search-input.sync="search"
        color="white"
        hide-no-data
        hide-selected
        item-text="Description"
        item-value="dedication"
        label="Dedications"
        placeholder="Start typing to Search"
        prepend-icon="mdi-magnify"
        return-object
        @input="onInput"
      ></v-autocomplete>
    </v-card-text>
  </v-col>
</template>

<script>
import { mapGetters } from 'vuex'
import { GET_ALL_DEDICATIONS, LOAD_ALL_DEDICATIONS } from '@/services/constants'
export default {
  name: 'DedicationsSearch',
  data() {
    return {
      descriptionLimit: 60,
      isLoading: false,
      model: null,
      search: null,
    }
  },
  props: {
    app: {
      type: String,
      default: 'rcd',
    },
  },
  computed: {
    ...mapGetters([GET_ALL_DEDICATIONS]),
    items() {
      return this.entries
        .filter((entry) => entry.app === this.app)
        .map((entry) => {
          let Description = ''
          if (!entry.Description) {
            const text = entry.sponsor + ' ' + entry.type + ' ' + entry.honoree
            Description = text.length > this.descriptionLimit ? text.slice(0, this.descriptionLimit) + '...' : text
          } else {
            Description = entry.Description.length > this.descriptionLimit ? entry.Description.slice(0, this.descriptionLimit) + '...' : entry.Description
          }

          return Object.assign({}, entry, { Description })
        })
    },
    entries() {
      return this[GET_ALL_DEDICATIONS]
    },
  },
  watch: {
    async search() {
      // Items have already been loaded
      if (this.items.length > 0) return

      // Items have already been requested
      if (this.isLoading) return

      this.isLoading = true

      await this.$store.dispatch(LOAD_ALL_DEDICATIONS)

      this.loading = false
    },
  },
  methods: {
    onInput(input) {
      this.$emit('input', input)
    },
  },
}
</script>

<style scoped></style>
