import Vue from 'vue'
import Vuex from 'vuex'

import {
  // A_PER_ROUTE_ACTIONS,
  GET_IS_LOGGED_IN,
  INIT_AUTHENTICATED_STORE,
  INIT_AUTHENTICATED_USER_MODULE,
  INIT_STORE,
  INIT_USER_MODULE,
  LOAD_SESSION_FROM_COOKIE,
  PRE_INIT_STORE,
} from './services/constants'

import { httpModule } from './store-modules/httpModule'
import { userModule } from './store-modules/userModule'
import { dataModule } from './store-modules/dataModule'

Vue.use(Vuex)

export default new Vuex.Store({
  modules: {
    httpModule,
    userModule,
    dataModule,
  },
  state: {},
  mutations: {},
  actions: {
    [PRE_INIT_STORE]: (context) => {
      return Promise.all([context.dispatch(LOAD_SESSION_FROM_COOKIE)])
    },

    [INIT_STORE]: (context) => {
      const actions = []
      actions.push(context.dispatch(INIT_USER_MODULE))
      if (context.getters[GET_IS_LOGGED_IN]) {
        actions.push(context.dispatch(INIT_AUTHENTICATED_STORE))
      }
      return Promise.all(actions)
    },
    [INIT_AUTHENTICATED_STORE]: (context) => {
      return Promise.all([context.dispatch(INIT_AUTHENTICATED_USER_MODULE)])
    },
    // [A_PER_ROUTE_ACTIONS]: (context, toRoute) => {
    //   return Promise.all([])
    // },
  },
})
