import Vue from 'vue'
import router from './router'
import store from './store'
import vuetify from './plugins/vuetify'
import Vue2Editor from 'vue2-editor'
import VuePlyr from 'vue-plyr'
import 'vue-plyr/dist/vue-plyr.css'
Vue.use(Vue2Editor)
Vue.use(VuePlyr, {
  plyr: {},
})
import VueMeta from 'vue-meta'
Vue.use(VueMeta, {
  // optional pluginOptions
  refreshOnceOnNavigation: true,
})

import { INIT_STORE, PRE_INIT_STORE } from './services/constants'
import AppContainer from './AppContainer'

Vue.config.productionTip = false

store.dispatch(PRE_INIT_STORE)

new Vue({
  router,
  store,
  vuetify,
  created() {
    store.dispatch(INIT_STORE)
  },
  render: (h) => h(AppContainer),
}).$mount('#app')
