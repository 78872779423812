<template>
  <div style="padding: 20px 25px">
    <v-data-table
      :headers="headers"
      :items="allMessages"
      :items-per-page="numberOfMessagesPerPage"
      class="elevation-1"
      :loading="messagesLoading"
      loading-text="Loading... Please wait"
      hide-default-footer
      @click:row="displayMessage"
    >
      <template v-slot:item.actions="{ item }">
        <v-icon small class="mr-2" @click.stop="editItem(item)"> mdi-pencil </v-icon>
        <v-icon small @click.stop="openDeleteItemDialog(item)"> mdi-delete </v-icon>
      </template>
      <template v-slot:top>
        <v-dialog v-model="dialog" @click:outside="close" max-width="500px">
          <v-card>
            <v-card-title>
              <span class="headline">{{ title }}</span>
            </v-card-title>
            <v-card-text v-if="isEditingMessage">
              <v-container>
                <v-row>
                  <v-col cols="12" sm="6" md="6">
                    <v-text-field v-model="editedItem.subject" label="Subject"></v-text-field>
                  </v-col>
                </v-row>
                <v-row>
                  <v-col cols="12" sm="8" md="8">
                    <v-menu
                      v-model="removeDateEditingMenu"
                      :close-on-content-click="false"
                      :nudge-right="40"
                      transition="scale-transition"
                      offset-x
                      max-width="290px"
                      min-width="290px"
                    >
                      <template v-slot:activator="{ on }">
                        <v-text-field label="Remove Date" readonly :value="removeDateDispForEdit" v-on="on"></v-text-field>
                      </template>
                      <v-date-picker v-model="editedItem.removeDate" @input="removeDateEditingMenu = false"></v-date-picker>
                    </v-menu>
                  </v-col>
                </v-row>
                <v-row>
                  <v-col cols="12" sm="12" md="12">
                    <vue-editor v-model="editedItem.body" :editorToolbar="customToolBar" />
                  </v-col>
                </v-row>
              </v-container>
            </v-card-text>
            <v-card-actions>
              <v-spacer></v-spacer>
              <v-btn color="blue darken-1" text @click="close">Cancel</v-btn>
              <v-btn v-if="isEditingMessage" :loading="updateMessageLoading" color="blue darken-1" text @click="save">Save</v-btn>
              <v-btn v-if="isDeleteMessage" :loading="deleteMessageLoading" color="blue darken-1" text @click="deleteItem()">Delete</v-btn>
            </v-card-actions>
          </v-card>
        </v-dialog>
        <v-dialog v-model="displayMessageDialog" @click:outside="close" max-width="500px">
          <v-card>
            <v-card-title>
              <span class="headline">{{ selectedMessageTitle }}</span>
            </v-card-title>
            <v-card-text>
              <span v-html="selectedMessageBody"></span>
            </v-card-text>
          </v-card>
        </v-dialog>
      </template>
    </v-data-table>
    <div class="create-message">
      <v-row>
        <v-col cols="12" sm="4" md="4">
          <div class="headline lighten-2">Post New Message</div>
        </v-col>
      </v-row>
      <v-row>
        <v-col cols="12" sm="4" md="4">
          <v-text-field v-model="newMessage.subject" label="Subject"></v-text-field>
        </v-col>
      </v-row>
      <v-row>
        <v-col cols="12" sm="6" md="6">
          <vue-editor v-model="newMessage.body" :editorToolbar="customToolBar" />
        </v-col>
      </v-row>
      <v-row>
        <v-col cols="12" sm="4" md="4">
          <v-menu
            v-model="removeDateMenu"
            :close-on-content-click="false"
            :nudge-right="40"
            transition="scale-transition"
            offset-x
            max-width="290px"
            min-width="290px"
          >
            <template v-slot:activator="{ on }">
              <v-text-field label="Remove Date" readonly :value="removeDateDisp" v-on="on"></v-text-field>
            </template>
            <v-date-picker v-model="newMessage.removeDate" @input="removeDateMenu = false"></v-date-picker>
          </v-menu>
        </v-col>
      </v-row>
      <v-row>
        <v-col cols="12" sm="6" md="6" class="text-right">
          <v-btn text secondary @click="createMessage" :loading="createMessageLoading"> Create </v-btn>
        </v-col>
      </v-row>
    </div>
  </div>
</template>

<script>
import { mapGetters } from 'vuex'
import { format } from 'date-fns'
import { VueEditor } from 'vue2-editor'
import {
  CREATE_MESSAGE,
  DELETE_MESSAGE,
  GET_MESSAGES,
  IS_CREATE_MESSAGE_LOADING,
  IS_DELETE_MESSAGE_LOADING,
  IS_MESSAGES_LOADING,
  IS_UPDATE_MESSAGE_LOADING,
  LOAD_MESSAGES,
  UPDATE_MESSAGE,
  DISPLAY_DATE_FORMAT,
} from '@/services/constants'

export default {
  name: 'Messages',
  components: { VueEditor },
  props: {
    app: {
      type: String,
      default: 'rcd',
    },
  },
  data() {
    return {
      headers: [
        {
          text: 'Date',
          align: 'start',
          sortable: false,
          value: 'sent',
        },
        { text: 'Subject', value: 'subject' },
        { text: 'Actions', value: 'actions', sortable: false },
      ],
      dialog: false,
      isDelete: false,
      editedIndex: -1,
      editedItem: {
        id: -1,
        subject: '',
        body: '',
        removeDate: '',
        published: '',
        app: this.app,
      },
      itemToDelete: {},
      displayMessageDialog: false,
      selectedItem: {},
      newMessage: {
        subject: '',
        body: '',
        removeDate: null,
        app: this.app,
      },
      removeDateMenu: false,
      removeDateEditingMenu: false,
    }
  },

  created() {
    this.$store.dispatch(LOAD_MESSAGES)
  },

  computed: {
    ...mapGetters([GET_MESSAGES, IS_MESSAGES_LOADING, IS_DELETE_MESSAGE_LOADING, IS_UPDATE_MESSAGE_LOADING, IS_CREATE_MESSAGE_LOADING]),
    allMessages() {
      return this[GET_MESSAGES].filter((message) => message.app === this.app).map((message) => {
        message.sent = format(new Date(message.sent), DISPLAY_DATE_FORMAT)
        return message
      })
    },
    messagesLoading() {
      return this[IS_MESSAGES_LOADING]
    },
    deleteMessageLoading() {
      return this[IS_DELETE_MESSAGE_LOADING]
    },
    updateMessageLoading() {
      return this[IS_UPDATE_MESSAGE_LOADING]
    },
    createMessageLoading() {
      return this[IS_CREATE_MESSAGE_LOADING]
    },
    numberOfMessagesPerPage() {
      return 1000
    },
    defaultItem() {
      return {
        id: -1,
        subject: '',
        body: '',
        removeDate: '',
        published: '',
        app: this.app,
      }
    },
    isEditingMessage() {
      return this.editedIndex >= 0
    },
    isDeleteMessage() {
      return this.isDelete
    },
    title() {
      if (this.isEditingMessage) {
        return 'Edit Message'
      } else if (this.isDeleteMessage) {
        return 'Delete Message'
      } else {
        return ''
      }
    },
    selectedMessageTitle() {
      return this.selectedItem.subject
    },
    selectedMessageBody() {
      return this.selectedItem.body
    },
    newMessageTemplate() {
      return {
        subject: '',
        body: '',
        removeDate: null,
        app: this.app,
      }
    },
    removeDateDisp() {
      return this.newMessage.removeDate
    },
    removeDateDispForEdit() {
      return this.editedItem.removeDate.substring(0, 10)
    },
    customToolBar() {
      return [['bold', 'italic'], [{ list: 'ordered' }, { list: 'bullet' }], ['link'], ['clean']]
    },
  },

  methods: {
    editItem(item) {
      this.editedIndex = this.allMessages.indexOf(item)
      this.editedItem = Object.assign({}, item)
      delete this.editedItem.sent
      this.dialog = true
    },
    openDeleteItemDialog(item) {
      this.dialog = true
      this.isDelete = true
      this.itemToDelete = item
    },
    deleteItem() {
      this.$store.dispatch(DELETE_MESSAGE, this.itemToDelete.id).then(() => {
        this.$store.dispatch(LOAD_MESSAGES)
        this.close()
      })
    },
    close() {
      this.dialog = false
      this.displayMessageDialog = false
      this.$nextTick(() => {
        this.editedItem = Object.assign({}, this.defaultItem)
        this.editedIndex = -1
        this.isDelete = false
        this.itemToDelete = {}
        this.selectedItem = {}
        this.newMessage = Object.assign({}, this.newMessageTemplate)
        this.removeDateMenu = false
        this.removeDateEditingMenu = false
      })
    },
    save() {
      this.$store.dispatch(UPDATE_MESSAGE, this.editedItem).then(() => {
        this.$store.dispatch(LOAD_MESSAGES)
        this.close()
      })
    },
    displayMessage(item) {
      console.log('row: ', item)
      this.selectedItem = item
      this.displayMessageDialog = true
    },
    createMessage() {
      this.$store.dispatch(CREATE_MESSAGE, this.newMessage).then(() => {
        this.$store.dispatch(LOAD_MESSAGES)
        this.close()
      })
    },
  },
}
</script>

<style scoped lang="scss"></style>
