import {
  CREATE_AUDIO_DEDICATION,
  CREATE_DEDICATION,
  CREATE_MESSAGE,
  CREATE_PRESIGNED_URL,
  DELETE_AUDIO_DEDICATION,
  DELETE_DEDICATION,
  DELETE_MESSAGE,
  DELETE_USER,
  GET_BACKEND_URL,
  GET_HTTP_CONFIG,
  GET_MESSAGES,
  GET_MONTHLY_AUDIO_DEDICATIONS,
  GET_MONTHLY_DEDICATIONS,
  GET_PRESIGNED_URL,
  GET_USERS,
  IS_ALL_USERS_LOADED,
  IS_CREATE_AUDIO_DEDICATION_COMPLETE,
  IS_CREATE_MESSAGE_LOADING,
  IS_DELETE_MESSAGE_LOADING,
  IS_DELETE_USER_LOADING,
  IS_LOADED_TO_S3_COMPLETE,
  IS_MESSAGES_LOADING,
  IS_MONTHLY_AUDIO_DEDICATIONS_LOADED,
  IS_MONTHLY_DEDICATIONS_LOADED,
  IS_UPDATE_MESSAGE_LOADING,
  IS_UPDATE_USER_LOADING,
  IS_USERS_LOADED,
  LOAD_ALL_USERS,
  LOAD_INITIAL_USERS,
  LOAD_MESSAGES,
  LOAD_MONTHLY_AUDIO_DEDICATIONS,
  LOAD_MONTHLY_DEDICATIONS,
  LOADING_COMPLETED_FAILURE,
  LOADING_COMPLETED_SUCCESS,
  LOADING_PRISTINE,
  LOADING_STARTED,
  SET_ALL_USERS_LOADING_STATE,
  SET_AUDIO_DEDICATION,
  SET_CREATE_MESSAGE_LOADING_STATE,
  SET_DELETE_MESSAGE_LOADING_STATE,
  SET_DELETE_USER_LOADING_STATE,
  SET_MESSAGES,
  SET_MONTHLY_AUDIO_DEDICATIONS,
  SET_MONTHLY_DEDICATIONS,
  SET_PRESIGNED_URL,
  SET_UPDATE_MESSAGE_LOADING_STATE,
  SET_UPDATE_USER_LOADING_STATE,
  SET_UPLOAD_TO_S3_BUCKET,
  SET_USERS,
  UPDATE_AUDIO_DEDICATION,
  UPDATE_DEDICATION,
  UPDATE_MESSAGE,
  UPDATE_USER,
  UPLOAD_TO_S3,
  SET_NOTIFICATIONS,
  LOAD_NOTIFICATIONS,
  GET_NOTIFICATIONS,
  IS_NOTIFICATIONS_LOADING,
  CREATE_NOTIFICATION,
  SET_CREATE_NOTIFICATION_LOADING_STATE,
  IS_CREATE_NOTIFICATION_LOADING,
  GET_PRESIGNED_KEY,
  GET_VIDEOS,
  IS_VIDEOS_LOADING,
  LOAD_VIDEOS,
  SET_VIDEOS,
  SET_UPLOAD_VIDEO_LOADING_STATE,
  CREATE_VIDEO,
  LOAD_ALL_DEDICATIONS,
  SET_ALL_DEDICATIONS,
  GET_ALL_DEDICATIONS,
} from '@/services/constants'
import http from '../services/axios'

const module = {
  state: {
    monthDedications: {
      loadingState: LOADING_PRISTINE,
      data: [],
      error: null,
    },
    monthAudioDedications: {
      loadingState: LOADING_PRISTINE,
      data: [],
      error: null,
    },
    users: {
      loadingState: LOADING_PRISTINE,
      data: [],
      error: null,
    },
    allUsersLoadingState: LOADING_PRISTINE,
    deleteUserLoadingState: LOADING_PRISTINE,
    updateUserLoadingState: LOADING_PRISTINE,
    messages: {
      loadingState: LOADING_PRISTINE,
      data: [],
      error: null,
    },
    deleteMessageLoadingState: LOADING_PRISTINE,
    updateMessageLoadingState: LOADING_PRISTINE,
    createMessageLoadingState: LOADING_PRISTINE,
    createNotificationLoadingState: LOADING_PRISTINE,
    presignedUrl: {
      loadingState: LOADING_PRISTINE,
      data: {},
      error: null,
    },
    uploadToS3Bucket: {
      loadingState: LOADING_PRISTINE,
      data: null,
      error: null,
    },
    audioDedication: {
      loadingState: LOADING_PRISTINE,
      data: null,
      error: null,
    },
    notifications: {
      loadingState: LOADING_PRISTINE,
      data: [],
      error: null,
    },
    videos: {
      loadingState: LOADING_PRISTINE,
      data: [],
      error: null,
    },
    allDedications: {
      loadingState: LOADING_PRISTINE,
      data: [],
      error: null,
    },
  },
  getters: {
    [GET_MONTHLY_DEDICATIONS](state) {
      return state.monthDedications.data
    },
    [IS_MONTHLY_DEDICATIONS_LOADED](state) {
      return state.monthDedications.loadingState === LOADING_COMPLETED_SUCCESS
    },
    [GET_MONTHLY_AUDIO_DEDICATIONS](state) {
      return state.monthAudioDedications.data
    },
    [IS_MONTHLY_AUDIO_DEDICATIONS_LOADED](state) {
      return state.monthAudioDedications.loadingState === LOADING_COMPLETED_SUCCESS
    },
    [GET_USERS](state) {
      return state.users.data
    },
    [IS_USERS_LOADED](state) {
      return state.users.loadingState === LOADING_COMPLETED_SUCCESS
    },
    [IS_ALL_USERS_LOADED](state) {
      return state.allUsersLoadingState === LOADING_COMPLETED_SUCCESS
    },
    [IS_DELETE_USER_LOADING](state) {
      return state.deleteUserLoadingState === LOADING_STARTED
    },
    [IS_UPDATE_USER_LOADING](state) {
      return state.updateUserLoadingState === LOADING_STARTED
    },
    [GET_MESSAGES](state) {
      return state.messages.data
    },
    [IS_MESSAGES_LOADING](state) {
      return state.messages.loadingState === LOADING_STARTED
    },
    [IS_DELETE_MESSAGE_LOADING](state) {
      return state.deleteMessageLoadingState === LOADING_STARTED
    },
    [IS_UPDATE_MESSAGE_LOADING](state) {
      return state.updateMessageLoadingState === LOADING_STARTED
    },
    [IS_CREATE_MESSAGE_LOADING](state) {
      return state.createMessageLoadingState === LOADING_STARTED
    },
    [GET_PRESIGNED_URL](state) {
      console.log('data: ', state.presignedUrl.data)
      return state.presignedUrl.data.url
    },
    [GET_PRESIGNED_KEY](state) {
      return state.presignedUrl.data.key
    },
    [IS_LOADED_TO_S3_COMPLETE](state) {
      return state.uploadToS3Bucket.loadingState === LOADING_COMPLETED_SUCCESS
    },
    [IS_CREATE_AUDIO_DEDICATION_COMPLETE](state) {
      return state.audioDedication.loadingState === LOADING_COMPLETED_SUCCESS
    },
    [GET_NOTIFICATIONS](state) {
      return state.notifications.data
    },
    [IS_NOTIFICATIONS_LOADING](state) {
      return state.notifications.loadingState === LOADING_STARTED
    },
    [IS_CREATE_NOTIFICATION_LOADING](state) {
      return state.createNotificationLoadingState === LOADING_STARTED
    },
    [GET_VIDEOS](state) {
      return state.videos.data
    },
    [IS_VIDEOS_LOADING](state) {
      return state.videos.loadingState === LOADING_STARTED
    },
    [GET_ALL_DEDICATIONS](state) {
      return state.allDedications.data
    },
  },
  mutations: {
    [SET_MONTHLY_DEDICATIONS]: (state, payload) => {
      state.monthDedications = payload
    },
    [SET_MONTHLY_AUDIO_DEDICATIONS]: (state, payload) => {
      state.monthAudioDedications = payload
    },
    [SET_USERS]: (state, payload) => {
      state.users = payload
    },
    [SET_ALL_USERS_LOADING_STATE]: (state, payload) => {
      state.allUsersLoadingState = payload
    },
    [SET_DELETE_USER_LOADING_STATE]: (state, payload) => {
      state.deleteUserLoadingState = payload
    },
    [SET_UPDATE_USER_LOADING_STATE]: (state, payload) => {
      state.updateUserLoadingState = payload
    },
    [SET_MESSAGES]: (state, payload) => {
      state.messages = payload
    },
    [SET_DELETE_MESSAGE_LOADING_STATE]: (state, payload) => {
      state.deleteMessageLoadingState = payload
    },
    [SET_UPDATE_MESSAGE_LOADING_STATE]: (state, payload) => {
      state.updateMessageLoadingState = payload
    },
    [SET_CREATE_MESSAGE_LOADING_STATE]: (state, payload) => {
      state.createMessageLoadingState = payload
    },
    [SET_PRESIGNED_URL](state, payload) {
      return (state.presignedUrl = payload)
    },
    [SET_UPLOAD_TO_S3_BUCKET](state, payload) {
      return (state.uploadToS3Bucket = payload)
    },
    [SET_AUDIO_DEDICATION](state, payload) {
      return (state.audioDedication = payload)
    },
    [SET_NOTIFICATIONS](state, payload) {
      return (state.notifications = payload)
    },
    [SET_CREATE_NOTIFICATION_LOADING_STATE]: (state, payload) => {
      state.createNotificationLoadingState = payload
    },
    [SET_VIDEOS](state, payload) {
      return (state.videos = payload)
    },
    [SET_UPLOAD_VIDEO_LOADING_STATE]: (state, payload) => {
      state.createVideoLoadingState = payload
    },
    [SET_ALL_DEDICATIONS](state, payload) {
      return (state.allDedications = payload)
    },
  },

  actions: {
    [LOAD_MONTHLY_DEDICATIONS]: (context, date) => {
      context.commit(SET_MONTHLY_DEDICATIONS, {
        loadingState: LOADING_STARTED,
        data: [],
        error: null,
      })
      return http
        .get(context.getters[GET_BACKEND_URL](`admin/dedications/${date.year}/${date.month}`), context.getters[GET_HTTP_CONFIG])
        .then((response) => {
          console.log('response:... ', response)
          if (response.status === 200) {
            context.commit(SET_MONTHLY_DEDICATIONS, {
              loadingState: LOADING_COMPLETED_SUCCESS,
              data: response.data,
              error: null,
            })
          } else {
            throw new Error('there is an error')
          }
        })
        .catch((err) => {
          console.error(err)
          context.commit(SET_MONTHLY_DEDICATIONS, {
            loadingState: LOADING_COMPLETED_FAILURE,
            data: [],
            error: err.message,
          })
        })
    },
    [LOAD_ALL_DEDICATIONS]: (context, query = '') => {
      context.commit(SET_ALL_DEDICATIONS, {
        loadingState: LOADING_STARTED,
        data: [],
        error: null,
      })
      return http
        .get(context.getters[GET_BACKEND_URL](`admin/dedications?q=${query}`), context.getters[GET_HTTP_CONFIG])
        .then((response) => {
          console.log('response:... ', response)
          if (response.status === 200) {
            context.commit(SET_ALL_DEDICATIONS, {
              loadingState: LOADING_COMPLETED_SUCCESS,
              data: response.data,
              error: null,
            })
          } else {
            throw new Error('there is an error')
          }
        })
        .catch((err) => {
          console.error(err)
          context.commit(SET_ALL_DEDICATIONS, {
            loadingState: LOADING_COMPLETED_FAILURE,
            data: [],
            error: err.message,
          })
        })
    },
    [CREATE_DEDICATION]: (context, payload) => {
      return http
        .post(context.getters[GET_BACKEND_URL](`admin/dedications`), payload, context.getters[GET_HTTP_CONFIG])
        .then((response) => {
          console.log(response)
          if (response.status === 200) {
            return true
          } else {
            throw new Error('there is an error')
          }
        })
        .catch((err) => {
          console.error(err)
        })
    },
    [DELETE_DEDICATION]: (context, id) => {
      return http
        .delete(context.getters[GET_BACKEND_URL](`admin/dedications/${id}`), context.getters[GET_HTTP_CONFIG])
        .then((response) => {
          console.log(response)
          if (response.status === 200) {
            return true
          } else {
            throw new Error('there is an error')
          }
        })
        .catch((err) => {
          console.error(err)
        })
    },
    [UPDATE_DEDICATION]: (context, dedication) => {
      return http
        .post(context.getters[GET_BACKEND_URL](`admin/dedications/${dedication.id}`), dedication.payload, context.getters[GET_HTTP_CONFIG])
        .then((response) => {
          console.log(response)
          if (response.status === 200) {
            return true
          } else {
            throw new Error('there is an error')
          }
        })
        .catch((err) => {
          console.error(err)
        })
    },
    [LOAD_INITIAL_USERS]: (context, queryParams) => {
      context.commit(SET_USERS, {
        loadingState: LOADING_STARTED,
        data: [],
        error: null,
      })
      return http
        .get(context.getters[GET_BACKEND_URL](`admin/users?offset=${queryParams.offset}&count=${queryParams.count}`), context.getters[GET_HTTP_CONFIG])
        .then((response) => {
          console.log(response)
          if (response.status === 200) {
            context.commit(SET_USERS, {
              loadingState: LOADING_COMPLETED_SUCCESS,
              data: response.data,
              error: null,
            })
          } else {
            throw new Error('there is an error')
          }
        })
        .catch((err) => {
          console.error(err)
          context.commit(SET_USERS, {
            loadingState: LOADING_COMPLETED_FAILURE,
            data: [],
            error: err.message,
          })
        })
    },
    [LOAD_ALL_USERS]: (context) => {
      context.commit(SET_ALL_USERS_LOADING_STATE, LOADING_STARTED)
      return http
        .get(context.getters[GET_BACKEND_URL](`admin/users`), context.getters[GET_HTTP_CONFIG])
        .then((response) => {
          console.log(response)
          if (response.status === 200) {
            context.commit(SET_USERS, {
              loadingState: LOADING_COMPLETED_SUCCESS,
              data: response.data,
              error: null,
            })
            context.commit(SET_ALL_USERS_LOADING_STATE, LOADING_COMPLETED_SUCCESS)
          } else {
            throw new Error('there is an error')
          }
        })
        .catch((err) => {
          context.commit(SET_ALL_USERS_LOADING_STATE, LOADING_COMPLETED_FAILURE)
          console.error(err)
        })
    },
    [UPDATE_USER]: (context, user) => {
      context.commit(SET_UPDATE_USER_LOADING_STATE, LOADING_STARTED)
      return http
        .post(context.getters[GET_BACKEND_URL](`admin/users/${user.id}`), user, context.getters[GET_HTTP_CONFIG])
        .then((response) => {
          console.log(response)
          if (response.status === 200) {
            context.commit(SET_UPDATE_USER_LOADING_STATE, LOADING_COMPLETED_SUCCESS)
            return true
          } else {
            context.commit(SET_UPDATE_USER_LOADING_STATE, LOADING_COMPLETED_FAILURE)
            throw new Error('there is an error')
          }
        })
        .catch((err) => {
          console.error(err)
          context.commit(SET_UPDATE_USER_LOADING_STATE, LOADING_COMPLETED_FAILURE)
        })
    },
    [DELETE_USER]: (context, id) => {
      context.commit(SET_DELETE_USER_LOADING_STATE, LOADING_STARTED)
      return http
        .delete(context.getters[GET_BACKEND_URL](`admin/users/${id}`), context.getters[GET_HTTP_CONFIG])
        .then((response) => {
          console.log(response)
          if (response.status === 200) {
            context.commit(SET_DELETE_USER_LOADING_STATE, LOADING_COMPLETED_SUCCESS)
            return true
          } else {
            context.commit(SET_DELETE_USER_LOADING_STATE, LOADING_COMPLETED_FAILURE)
            throw new Error('there is an error')
          }
        })
        .catch((err) => {
          console.error(err)
          context.commit(SET_DELETE_USER_LOADING_STATE, LOADING_COMPLETED_FAILURE)
        })
    },
    [LOAD_MESSAGES]: (context) => {
      context.commit(SET_MESSAGES, {
        loadingState: LOADING_STARTED,
        data: [],
        error: null,
      })
      return http
        .get(context.getters[GET_BACKEND_URL](`admin/messages`), context.getters[GET_HTTP_CONFIG])
        .then((response) => {
          console.log(response)
          if (response.status === 200) {
            context.commit(SET_MESSAGES, {
              loadingState: LOADING_COMPLETED_SUCCESS,
              data: response.data,
              error: null,
            })
          } else {
            throw new Error('there is an error')
          }
        })
        .catch((err) => {
          console.error(err)
          context.commit(SET_MESSAGES, {
            loadingState: LOADING_COMPLETED_FAILURE,
            data: [],
            error: err.message,
          })
        })
    },
    [LOAD_NOTIFICATIONS]: (context) => {
      context.commit(SET_NOTIFICATIONS, {
        loadingState: LOADING_STARTED,
        data: [],
        error: null,
      })
      return http
        .get(context.getters[GET_BACKEND_URL](`admin/notifications`), context.getters[GET_HTTP_CONFIG])
        .then((response) => {
          console.log(response)
          if (response.status === 200) {
            context.commit(SET_NOTIFICATIONS, {
              loadingState: LOADING_COMPLETED_SUCCESS,
              data: response.data,
              error: null,
            })
          } else {
            throw new Error('there is an error')
          }
        })
        .catch((err) => {
          console.error(err)
          context.commit(SET_NOTIFICATIONS, {
            loadingState: LOADING_COMPLETED_FAILURE,
            data: [],
            error: err.message,
          })
        })
    },
    [DELETE_MESSAGE]: (context, id) => {
      context.commit(SET_DELETE_MESSAGE_LOADING_STATE, LOADING_STARTED)
      return http
        .delete(context.getters[GET_BACKEND_URL](`admin/messages/${id}`), context.getters[GET_HTTP_CONFIG])
        .then((response) => {
          console.log(response)
          if (response.status === 200) {
            context.commit(SET_DELETE_MESSAGE_LOADING_STATE, LOADING_COMPLETED_SUCCESS)
            return true
          } else {
            context.commit(SET_DELETE_MESSAGE_LOADING_STATE, LOADING_COMPLETED_FAILURE)
            throw new Error('there is an error')
          }
        })
        .catch((err) => {
          context.commit(SET_DELETE_MESSAGE_LOADING_STATE, LOADING_COMPLETED_FAILURE)
          console.error(err)
        })
    },
    [UPDATE_MESSAGE]: (context, message) => {
      context.commit(SET_UPDATE_MESSAGE_LOADING_STATE, LOADING_STARTED)
      return http
        .post(context.getters[GET_BACKEND_URL](`admin/messages/${message.id}`), message, context.getters[GET_HTTP_CONFIG])
        .then((response) => {
          console.log(response)
          if (response.status === 200) {
            context.commit(SET_UPDATE_MESSAGE_LOADING_STATE, LOADING_COMPLETED_SUCCESS)
            return true
          } else {
            context.commit(SET_UPDATE_MESSAGE_LOADING_STATE, LOADING_COMPLETED_FAILURE)
            throw new Error('there is an error')
          }
        })
        .catch((err) => {
          context.commit(SET_UPDATE_MESSAGE_LOADING_STATE, LOADING_COMPLETED_FAILURE)
          console.error(err)
        })
    },
    [CREATE_MESSAGE]: (context, message) => {
      context.commit(SET_CREATE_MESSAGE_LOADING_STATE, LOADING_STARTED)
      return http
        .post(context.getters[GET_BACKEND_URL](`admin/messages`), message, context.getters[GET_HTTP_CONFIG])
        .then((response) => {
          console.log(response)
          if (response.status === 200) {
            context.commit(SET_CREATE_MESSAGE_LOADING_STATE, LOADING_COMPLETED_SUCCESS)
            return true
          } else {
            context.commit(SET_CREATE_MESSAGE_LOADING_STATE, LOADING_COMPLETED_FAILURE)
            throw new Error('there is an error')
          }
        })
        .catch((err) => {
          context.commit(SET_CREATE_MESSAGE_LOADING_STATE, LOADING_COMPLETED_FAILURE)
          console.error(err)
        })
    },
    [CREATE_NOTIFICATION]: (context, notification) => {
      context.commit(SET_CREATE_NOTIFICATION_LOADING_STATE, LOADING_STARTED)
      return http
        .post(context.getters[GET_BACKEND_URL](`admin/notifications`), notification, context.getters[GET_HTTP_CONFIG])
        .then((response) => {
          console.log(response)
          if (response.status === 200) {
            context.commit(SET_CREATE_NOTIFICATION_LOADING_STATE, LOADING_COMPLETED_SUCCESS)
            return true
          } else {
            context.commit(SET_CREATE_NOTIFICATION_LOADING_STATE, LOADING_COMPLETED_FAILURE)
            throw new Error('there is an error')
          }
        })
        .catch((err) => {
          context.commit(SET_CREATE_NOTIFICATION_LOADING_STATE, LOADING_COMPLETED_FAILURE)
          console.error(err)
        })
    },
    [CREATE_PRESIGNED_URL]: (context, presignedUrlObject) => {
      context.commit(SET_PRESIGNED_URL, {
        loadingState: LOADING_STARTED,
        data: {},
        error: null,
      })
      console.log(presignedUrlObject)
      return http
        .post(context.getters[GET_BACKEND_URL](`storage/blobs/upload`), presignedUrlObject, context.getters[GET_HTTP_CONFIG])
        .then((response) => {
          console.log(response)
          if (response.status === 200) {
            context.commit(SET_PRESIGNED_URL, {
              loadingState: LOADING_COMPLETED_SUCCESS,
              data: response.data,
              error: null,
            })
          } else {
            throw new Error('there is an error')
          }
        })
        .catch((err) => {
          console.error(err)
          context.commit(SET_PRESIGNED_URL, {
            loadingState: LOADING_COMPLETED_FAILURE,
            data: {},
            error: null,
          })
        })
    },
    [UPLOAD_TO_S3]: (context, payload) => {
      context.commit(SET_UPLOAD_TO_S3_BUCKET, {
        loadingState: LOADING_STARTED,
        data: null,
        error: null,
      })
      const httpConfig = {
        headers: {
          'Content-Type': payload.file.type,
        },
      }
      console.log({ payload })
      return http
        .put(payload.url, payload.file, httpConfig)
        .then((response) => {
          console.log(response)
          if (response.status === 200) {
            context.commit(SET_UPLOAD_TO_S3_BUCKET, {
              loadingState: LOADING_COMPLETED_SUCCESS,
              data: response.data,
              error: null,
            })
          } else {
            throw new Error('there is an error')
          }
        })
        .catch((err) => {
          console.error(err)
          context.commit(SET_UPLOAD_TO_S3_BUCKET, {
            loadingState: LOADING_COMPLETED_FAILURE,
            data: null,
            error: null,
          })
        })
    },
    [CREATE_AUDIO_DEDICATION]: (context, payload) => {
      context.commit(SET_AUDIO_DEDICATION, {
        loadingState: LOADING_STARTED,
        data: null,
        error: null,
      })
      return http
        .post(context.getters[GET_BACKEND_URL](`admin/audio`), payload, context.getters[GET_HTTP_CONFIG])
        .then((response) => {
          console.log(response)
          if (response.status === 200) {
            context.commit(SET_AUDIO_DEDICATION, {
              loadingState: LOADING_COMPLETED_SUCCESS,
              data: response.data,
              error: null,
            })
          } else {
            throw new Error('there is an error')
          }
        })
        .catch((err) => {
          console.error(err)
          context.commit(SET_AUDIO_DEDICATION, {
            loadingState: LOADING_COMPLETED_FAILURE,
            data: null,
            error: null,
          })
        })
    },
    [LOAD_MONTHLY_AUDIO_DEDICATIONS]: (context, date) => {
      context.commit(SET_MONTHLY_AUDIO_DEDICATIONS, {
        loadingState: LOADING_STARTED,
        data: [],
        error: null,
      })
      return http
        .get(context.getters[GET_BACKEND_URL](`admin/audio/${date.year}/${date.month}`), context.getters[GET_HTTP_CONFIG])
        .then((response) => {
          console.log('response:... ', response)
          if (response.status === 200) {
            context.commit(SET_MONTHLY_AUDIO_DEDICATIONS, {
              loadingState: LOADING_COMPLETED_SUCCESS,
              data: response.data,
              error: null,
            })
          } else {
            throw new Error('there is an error')
          }
        })
        .catch((err) => {
          console.error(err)
          context.commit(SET_MONTHLY_AUDIO_DEDICATIONS, {
            loadingState: LOADING_COMPLETED_FAILURE,
            data: [],
            error: err.message,
          })
        })
    },
    [DELETE_AUDIO_DEDICATION]: (context, id) => {
      return http
        .delete(context.getters[GET_BACKEND_URL](`admin/audio/${id}`), context.getters[GET_HTTP_CONFIG])
        .then((response) => {
          console.log('response:... ', response)
          if (response.status === 200) {
            return true
          } else {
            throw new Error('there is an error')
          }
        })
        .catch((err) => {
          console.error(err)
        })
    },
    [UPDATE_AUDIO_DEDICATION]: (context, dedication) => {
      return http
        .post(context.getters[GET_BACKEND_URL](`admin/audio/${dedication.id}`), dedication.payload, context.getters[GET_HTTP_CONFIG])
        .then((response) => {
          console.log(response)
          if (response.status === 200) {
            return true
          } else {
            throw new Error('there is an error')
          }
        })
        .catch((err) => {
          console.error(err)
        })
    },
    [LOAD_VIDEOS]: (context) => {
      return http
        .get(context.getters[GET_BACKEND_URL](`admin/videos`), context.getters[GET_HTTP_CONFIG])
        .then((response) => {
          console.log('response:... ', response)
          if (response.status === 200) {
            context.commit(SET_VIDEOS, {
              loadingState: LOADING_COMPLETED_SUCCESS,
              data: response.data,
              error: null,
            })
          } else {
            throw new Error('there is an error')
          }
        })
        .catch((err) => {
          console.error(err)
          context.commit(SET_VIDEOS, {
            loadingState: LOADING_COMPLETED_FAILURE,
            data: [],
            error: err.message,
          })
        })
    },
    [CREATE_VIDEO]: (context, payload) => {
      return http
        .post(context.getters[GET_BACKEND_URL](`admin/videos`), payload, context.getters[GET_HTTP_CONFIG])
        .then((response) => {
          console.log('response:... ', response)
          if (response.status === 200) {
            return true
          } else {
            throw new Error('there is an error')
          }
        })
        .catch((err) => {
          console.error(err)
        })
    },
  },
}

export default module
export const dataModule = module
