<template>
  <div style="padding: 0 25px">
    <v-data-table
      :headers="headers"
      :items="users"
      :items-per-page="numberOfUsersPerPage"
      :loading="!isUsersFinishedLoading"
      loading-text="Loading... Please wait"
      :search="search"
    >
      <template v-slot:top>
        <v-text-field v-model="search" label="Search" class="mx-4"></v-text-field>
      </template>
      <template v-slot:item.actions="{ item }">
        <v-icon small class="mr-2" @click="editItem(item)"> mdi-pencil </v-icon>
        <v-icon small @click="openDeleteItemDialog(item)"> mdi-delete </v-icon>
      </template>
      <template v-slot:body.append>
        <v-dialog v-model="dialog" max-width="500px">
          <v-card>
            <v-card-title>
              <span class="headline">{{ title }}</span>
            </v-card-title>
            <v-card-text v-if="isEditingUser">
              <v-container>
                <v-row>
                  <v-col cols="12" sm="6" md="6">
                    <v-text-field v-model="editedItem.name" label="Name"></v-text-field>
                  </v-col>
                </v-row>
                <v-row>
                  <v-col cols="12" sm="8" md="8">
                    <v-text-field v-model="editedItem.email" label="Email"></v-text-field>
                  </v-col>
                </v-row>
                <v-row>
                  <v-col cols="12" sm="12" md="12">
                    <v-textarea :auto-grow="true" rows="2" v-model="editedItem.comments" label="Comments"></v-textarea>
                  </v-col>
                </v-row>
              </v-container>
            </v-card-text>
            <v-card-actions>
              <v-spacer></v-spacer>
              <v-btn color="blue darken-1" text @click="close">Cancel</v-btn>
              <v-btn v-if="isEditingUser" :loading="isUpdateLoading" color="blue darken-1" text @click="save">Save</v-btn>
              <v-btn v-if="!isEditingUser" :loading="isDeleteLoading" color="blue darken-1" text @click="deleteItem()">Delete</v-btn>
            </v-card-actions>
          </v-card>
        </v-dialog>
      </template>
    </v-data-table>
  </div>
</template>

<script>
import { format } from 'date-fns'
import {
  DELETE_USER,
  GET_USERS,
  IS_ALL_USERS_LOADED,
  IS_DELETE_USER_LOADING,
  IS_UPDATE_USER_LOADING,
  IS_USERS_LOADED,
  LOAD_ALL_USERS,
  LOAD_INITIAL_USERS,
  UPDATE_USER,
  DISPLAY_DATE_FORMAT,
} from '@/services/constants'
import { mapGetters } from 'vuex'

export default {
  name: 'Users',

  data() {
    return {
      pageNumber: 1,
      headers: [
        {
          text: 'Honorific',
          align: 'start',
          sortable: false,
          value: 'honorific',
        },
        { text: 'Name', value: 'name' },
        { text: 'Email', value: 'email' },
        { text: 'Phone', value: 'phone' },
        { text: 'Signup', value: 'signup' },
        { text: 'Source', value: 'source' },
        { text: 'Comments', value: 'comments' },
        { text: 'Actions', value: 'actions', sortable: false },
      ],
      dialog: false,
      search: '',
      editedIndex: -1,
      editedItem: {
        id: -1,
        honorific: '',
        name: '',
        email: '',
        phone: '',
        signup: '',
        comments: '',
      },
      itemToDelete: {},
    }
  },

  created() {
    if (!this.areAllUsersAlreadyLoaded) {
      this.loadUsers()
    }
  },

  computed: {
    ...mapGetters([GET_USERS, IS_USERS_LOADED, IS_DELETE_USER_LOADING, IS_UPDATE_USER_LOADING, IS_ALL_USERS_LOADED]),
    areAllUsersAlreadyLoaded() {
      return this[IS_ALL_USERS_LOADED]
    },
    numberOfUsersPerPage() {
      return 50
    },
    getQueryParams() {
      return {
        offset: 0,
        count: 50,
      }
    },
    isUsersFinishedLoading() {
      return this[IS_USERS_LOADED]
    },
    users() {
      return this[GET_USERS].map((user) => {
        return {
          id: user.id,
          honorific: user.honorific,
          name: user.name,
          email: user.email,
          phone: user.phone,
          signup: format(new Date(user.created), DISPLAY_DATE_FORMAT),
          source: user.source.toUpperCase(),
          comments: user.comments,
        }
      })
    },
    defaultItem() {
      return {
        id: -1,
        honorific: '',
        name: '',
        email: '',
        phone: '',
        signup: '',
        comments: '',
      }
    },
    isEditingUser() {
      return this.editedIndex >= 0
    },
    title() {
      return this.isEditingUser ? 'Edit User' : 'Delete User'
    },
    isDeleteLoading() {
      return this[IS_DELETE_USER_LOADING]
    },
    isUpdateLoading() {
      return this[IS_UPDATE_USER_LOADING]
    },
  },

  methods: {
    editItem(item) {
      this.editedIndex = this.users.indexOf(item)
      this.editedItem = Object.assign({}, item)
      this.dialog = true
    },
    openDeleteItemDialog(item) {
      this.dialog = true
      this.itemToDelete = item
    },
    deleteItem() {
      this.$store.dispatch(DELETE_USER, this.itemToDelete.id).then(() => {
        this.loadUsers()
        this.close()
      })
    },
    close() {
      this.dialog = false
      this.$nextTick(() => {
        this.editedItem = Object.assign({}, this.defaultItem)
        this.editedIndex = -1
        this.itemToDelete = {}
      })
    },

    save() {
      this.$store.dispatch(UPDATE_USER, this.editedItem).then(() => {
        this.loadUsers()
        this.close()
      })
    },
    loadUsers() {
      this.$store.dispatch(LOAD_INITIAL_USERS, this.getQueryParams).then(() => {
        this.$store.dispatch(LOAD_ALL_USERS)
      })
    },
  },
}
</script>

<style scoped lang="scss"></style>
