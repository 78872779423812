<template>
  <v-container class="fill-height" fluid>
    <v-row align="center" justify="center">
      <v-col cols="12" sm="8" md="4">
        <v-card class="elevation-12">
          <v-toolbar color="primary" dark flat>
            <v-toolbar-title>Login form</v-toolbar-title>
          </v-toolbar>
          <v-card-text>
            <v-form id="check-login-form" v-on:submit.prevent="handleSubmit">
              <v-text-field v-model="username" label="Login" name="login" prepend-icon="mdi-account" type="text"></v-text-field>
              <v-text-field v-model="password" id="password" label="Password" name="password" prepend-icon="mdi-lock" type="password"></v-text-field>
            </v-form>
          </v-card-text>
          <v-card-actions>
            <v-spacer></v-spacer>
            <v-btn type="submit" @click="handleSubmit" color="primary" form="check-login-form">Login</v-btn>
          </v-card-actions>
        </v-card>
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
import { LOADING_COMPLETED_FAILURE, LOADING_COMPLETED_SUCCESS, LOGIN, R_NAME_DEDICATIONS } from '@/services/constants'

export default {
  name: 'Home',
  data() {
    return {
      username: '',
      password: '',
      failed: false,
    }
  },

  computed: {
    fieldsHaveInput() {
      return this.username.length > 0 && this.password.length
    },
    redirectRoute() {
      return { name: R_NAME_DEDICATIONS }
    },
    outgoingLoginModel() {
      return {
        username: this.username,
        password: this.password,
      }
    },
  },

  methods: {
    handleSubmit() {
      if (this.fieldsHaveInput) {
        this.login()
      }
    },
    async login() {
      try {
        await this.$store.dispatch(LOGIN, this.outgoingLoginModel)
        if (this.$store.state.userModule.session.loadingState === LOADING_COMPLETED_SUCCESS) {
          await this.$router.push(this.redirectRoute)
        } else if (this.$store.state.userModule.session.loadingState === LOADING_COMPLETED_FAILURE) {
          this.setToFailure()
        }
      } catch (e) {
        console.warn('component', e)
      }
    },
    setToFailure() {
      this.failed = true
    },
  },
}
</script>

<style scoped lang="scss"></style>
