// Tokens
export const AUTH_HEADER_TOKEN = 'Authorization'

// Cookie Service
export const SESSION_COOKIE_KEY = 'sessionCookieKey'

// Getters
export const GET_SESSION_TOKEN = 'getSessionToken'
export const GET_IS_LOGGED_IN = 'getIsLoggedIn'
export const GET_HTTP_CONFIG = 'getHttpConfig'
export const GET_FRONTEND_URL = 'getFrontendUrl'
export const GET_BACKEND_URL = 'getBackendUrl'
export const GET_MONTHLY_DEDICATIONS = 'getMonthlyDedications'
export const IS_MONTHLY_DEDICATIONS_LOADED = 'isMonthlyDedicationsLoaded'
export const GET_MONTHLY_AUDIO_DEDICATIONS = 'getMonthlyAudioDedications'
export const IS_MONTHLY_AUDIO_DEDICATIONS_LOADED = 'isMonthlyAudioDedicationsLoaded'
export const GET_USERS = 'getUsers'
export const IS_USERS_LOADED = 'isUsersLoaded'
export const IS_ALL_USERS_LOADED = 'isAllUsersLoaded'
export const IS_DELETE_USER_LOADING = 'isDeleteUserLoading'
export const IS_UPDATE_USER_LOADING = 'isUpdateUserLoading'
export const GET_MESSAGES = 'getMessages'
export const IS_MESSAGES_LOADING = 'isMessagesLoading'
export const IS_DELETE_MESSAGE_LOADING = 'isDeleteMessageLoading'
export const IS_UPDATE_MESSAGE_LOADING = 'isUpdateMessageLoading'
export const IS_CREATE_MESSAGE_LOADING = 'isCreateMessageLoading'
export const GET_PRESIGNED_URL = 'getPresignedUrl'
export const IS_LOADED_TO_S3_COMPLETE = 'isLoadedToS3Complete'
export const IS_CREATE_AUDIO_DEDICATION_COMPLETE = 'isCreateAudioDedicationComplete'
export const GET_NOTIFICATIONS = 'getNotifications'
export const IS_NOTIFICATIONS_LOADING = 'isNotificationsLoading'
export const IS_CREATE_NOTIFICATION_LOADING = 'isCreateNotificationLoading'
export const GET_PRESIGNED_KEY = 'getPresignedKey'
export const GET_VIDEOS = 'getVideos'
export const IS_VIDEOS_LOADING = 'isVideosLoading'
export const GET_ALL_DEDICATIONS = 'getAllDedications'

//Mutations
export const SET_SESSION = 'setSession'
export const SET_MONTHLY_DEDICATIONS = 'setMonthlyDedications'
export const SET_USERS = 'setUsers'
export const SET_ALL_USERS_LOADING_STATE = 'setAllUsersLoadingState'
export const SET_DELETE_USER_LOADING_STATE = 'setDeleteUserLoadingState'
export const SET_UPDATE_USER_LOADING_STATE = 'setUpdateUserLoadingState'
export const SET_MESSAGES = 'setMessages'
export const SET_DELETE_MESSAGE_LOADING_STATE = 'setDeleteMessageLoadingState'
export const SET_UPDATE_MESSAGE_LOADING_STATE = 'setUpdateMessageLoadingState'
export const SET_CREATE_MESSAGE_LOADING_STATE = 'setCreateMessageLoadingState'
export const SET_PRESIGNED_URL = 'setPresignedUrl'
export const SET_UPLOAD_TO_S3_BUCKET = 'setUploadToS3Bucket'
export const SET_AUDIO_DEDICATION = 'setAudioDedication'
export const SET_MONTHLY_AUDIO_DEDICATIONS = 'setMonthlyAudioDedications'
export const SET_NOTIFICATIONS = 'setNotifications'
export const SET_CREATE_NOTIFICATION_LOADING_STATE = 'setCreateNotificationLoadingState'
export const SET_VIDEOS = 'setVideos'
export const SET_UPLOAD_VIDEO_LOADING_STATE = 'setCreateVideoLoadingState'
export const SET_ALL_DEDICATIONS = 'setAllDedications'

// Actions
export const PRE_INIT_STORE = 'preInitStore'
export const INIT_STORE = 'initStore'
export const INIT_AUTHENTICATED_STORE = 'initAuthenticatedStore'
export const LOAD_SESSION_FROM_COOKIE = 'loadSessionFromCookie'
export const LOAD_MONTHLY_DEDICATIONS = 'loadMonthlyDedications'
export const INIT_USER_MODULE = 'initUserModule'
export const INIT_AUTHENTICATED_USER_MODULE = 'initAuthenticatedUserModule'
export const LOGIN = 'login'
export const LOGOUT = 'logout'
export const CREATE_DEDICATION = 'createDedication'
export const UPDATE_DEDICATION = 'updateDedication'
export const DELETE_DEDICATION = 'deleteDedication'
export const LOAD_INITIAL_USERS = 'loadInitialUsers'
export const LOAD_ALL_USERS = 'loadALLUsers'
export const DELETE_USER = 'deleteUsers'
export const UPDATE_USER = 'updateUsers'
export const LOAD_MESSAGES = 'loadMessages'
export const DELETE_MESSAGE = 'deleteMessage'
export const UPDATE_MESSAGE = 'updateMessage'
export const CREATE_MESSAGE = 'createMessage'
export const CREATE_PRESIGNED_URL = 'createPresignedUrl'
export const UPLOAD_TO_S3 = 'uploadToS3'
export const CREATE_AUDIO_DEDICATION = 'createAudioDedication'
export const LOAD_MONTHLY_AUDIO_DEDICATIONS = 'loadMonthlyAudioDedications'
export const DELETE_AUDIO_DEDICATION = 'deleteAudioDedication'
export const UPDATE_AUDIO_DEDICATION = 'updateAudioDedication'
export const LOAD_NOTIFICATIONS = 'loadNotifications'
export const CREATE_NOTIFICATION = 'createNotification'
export const LOAD_VIDEOS = 'loadVideos'
export const CREATE_VIDEO = 'createVideo'
export const LOAD_ALL_DEDICATIONS = 'loadAllDedications'

// Loading States
export const LOADING_PRISTINE = 0
export const LOADING_STARTED = 1
export const LOADING_COMPLETED_SUCCESS = 2
export const LOADING_COMPLETED_FAILURE = 3

//Route Names
export const R_NAME_DASHBOARD = 'dashboard'
export const R_NAME_DEDICATIONS = 'dedications'
export const R_NAME_DEDICATIONS_RCM = 'dedications-rcm'
export const R_NAME_USERS = 'users'
export const R_NAME_MESSAGES = 'messages'
export const R_NAME_MESSAGES_RCM = 'messages-rcm'
export const R_NAME_HOME = 'home'
export const R_NAME_NOTIFICATIONS = 'notifications'
export const R_NAME_VIDEOS = 'videos'
export const R_NAME_PLAYER = 'player'

// Right Click Action Types
export const RIGHT_CLICK_COPY = 'rightClickCopy'
export const RIGHT_CLICK_MOVE = 'rightClickMove'

export const DISPLAY_DATE_FORMAT = 'MMMM dd, yyyy, h:mm aaa'
