import axios from 'axios'

export default {
  get: (url, config) => {
    return axios.get(url, config).then((res) => {
      console.log(res.request.responseURL)
      return res
    })
  },
  post: (url, body, config) => {
    return axios.post(url, body, config).then((res) => {
      console.log(`POST ${res.request.responseURL}`)
      return res
    })
  },
  put: (url, body, config) => {
    return axios.put(url, body, config).then((res) => {
      console.log(`PUT ${res.request.responseURL}`)
      return res
    })
  },
  delete: (url, config) => {
    return axios.delete(url, config).then((res) => {
      console.log(`DELETE ${res.request.responseURL}`)
      return res
    })
  },
  all: (arrayOfRequests) => {
    return axios.all(arrayOfRequests)
  },
}
