import { GET_HTTP_CONFIG, GET_BACKEND_URL, AUTH_HEADER_TOKEN, GET_SESSION_TOKEN, GET_FRONTEND_URL } from '@/services/constants'
import config from '@/services/config'

const module = {
  state: {
    sessionCookie: '',
  },
  getters: {
    [GET_HTTP_CONFIG](state, getters) {
      const headers = {}
      const token = getters[GET_SESSION_TOKEN]
      if (token) {
        headers[AUTH_HEADER_TOKEN] = `Bearer ${token}`
      }

      return {
        headers,
      }
    },
    [GET_BACKEND_URL]() {
      return (path) => {
        return `${config.backendUrl}/${path}`
      }
    },
    [GET_FRONTEND_URL]() {
      return (path) => {
        return `${config.frontendUrl}/${path}`
      }
    },
  },
  mutations: {},
}

export default module
export const httpModule = module
