import CookieService from '@/services/cookies'
import http from '@/services/axios'

import {
  GET_BACKEND_URL,
  LOADING_PRISTINE,
  LOADING_STARTED,
  LOADING_COMPLETED_SUCCESS,
  LOADING_COMPLETED_FAILURE,
  GET_SESSION_TOKEN,
  GET_IS_LOGGED_IN,
  SET_SESSION,
  SESSION_COOKIE_KEY,
  LOAD_SESSION_FROM_COOKIE,
  LOGIN,
  INIT_AUTHENTICATED_STORE,
  LOGOUT,
  INIT_USER_MODULE,
  INIT_AUTHENTICATED_USER_MODULE,
} from '@/services/constants'

const module = {
  state: {
    session: {
      loadingState: LOADING_PRISTINE,
      data: null,
      error: null,
    },
  },
  getters: {
    [GET_SESSION_TOKEN](state) {
      return state.session.data
    },
    [GET_IS_LOGGED_IN](state, getters) {
      return !!getters[GET_SESSION_TOKEN]
    },
  },
  mutations: {
    [SET_SESSION]: (state, payload) => {
      state.session = payload
      if (state.session.data) {
        CookieService.set(SESSION_COOKIE_KEY, state.session.data)
      } else {
        CookieService.removeByKey(SESSION_COOKIE_KEY)
      }
    },
  },
  actions: {
    [LOAD_SESSION_FROM_COOKIE]: (context) => {
      const token = CookieService.getByKey(SESSION_COOKIE_KEY)
      context.commit(SET_SESSION, {
        loadingState: context.state.session.loadingState,
        data: token,
        error: context.state.session.error,
      })
    },
    [LOGIN]: (context, payload) => {
      context.commit(SET_SESSION, {
        loadingState: LOADING_STARTED,
        data: context.state.session.data,
        error: context.state.session.error,
      })
      return http
        .post(context.getters[GET_BACKEND_URL](`admin/sessions`), payload)
        .then((response) => {
          if (response.data.token) {
            context.commit(SET_SESSION, {
              loadingState: LOADING_COMPLETED_SUCCESS,
              data: response.data.token,
              error: null,
            })
            return context.dispatch(INIT_AUTHENTICATED_STORE)
          } else {
            throw new Error(response.data.message)
          }
        })
        .catch((err) => {
          console.error('store', err)
          context.commit(SET_SESSION, {
            loadingState: LOADING_COMPLETED_FAILURE,
            data: null,
            error: err.message,
          })
        })
    },
    [LOGOUT]: (context) => {
      context.commit(SET_SESSION, {
        state: LOADING_PRISTINE,
        data: null,
        error: null,
      })
    },
    [INIT_USER_MODULE]: (context) => {
      return http.all([context.dispatch(LOAD_SESSION_FROM_COOKIE)])
    },
    [INIT_AUTHENTICATED_USER_MODULE]: () => {
      let allActions = []
      return http.all(allActions)
    },
  },
}

export default module
export const userModule = module
