<template>
  <div style="padding: 20px 25px">
    <v-data-table
      :headers="headers"
      :items="allNotifications"
      :items-per-page="numberOfNotificationsPerPage"
      class="elevation-1"
      :loading="notificationsLoading"
      loading-text="Loading... Please wait"
      hide-default-footer
      @click:row="displayNotification"
    >
      <template v-slot:top>
        <v-dialog v-model="displayNotificationDialog" @click:outside="close" max-width="500px">
          <v-card>
            <v-card-title>
              <span class="headline">{{ selectedNotificationTitle }}</span>
            </v-card-title>
            <v-card-text>
              <span v-html="selectedNotificationBody"></span>
            </v-card-text>
          </v-card>
        </v-dialog>
      </template>
    </v-data-table>
    <div class="create-notification">
      <v-row>
        <v-col cols="12" sm="4" md="4">
          <div class="headline lighten-2">Send New Push Notification</div>
        </v-col>
      </v-row>
      <v-row>
        <v-col cols="12" sm="4" md="4">
          <v-text-field v-model="newNotification.title" label="Title" required></v-text-field>
        </v-col>
      </v-row>
      <v-row>
        <v-col cols="12" md="6" sm="6">
          <v-textarea v-model="newNotification.body" solo name="notification-body" label="Body" required></v-textarea>
        </v-col>
      </v-row>
      <v-row>
        <v-col cols="12" sm="6" md="6" class="text-right">
          <v-btn text secondary @click="createNotification" :loading="createNotificationLoading" :disabled="!isFormValid"> Send </v-btn>
        </v-col>
      </v-row>
    </div>
  </div>
</template>

<script>
import { mapGetters } from 'vuex'
import { format } from 'date-fns'
import {
  CREATE_NOTIFICATION,
  GET_NOTIFICATIONS,
  IS_CREATE_NOTIFICATION_LOADING,
  IS_NOTIFICATIONS_LOADING,
  LOAD_NOTIFICATIONS,
  DISPLAY_DATE_FORMAT,
} from '@/services/constants'

export default {
  name: 'Notifications',
  data() {
    return {
      headers: [
        {
          text: 'Date',
          align: 'start',
          sortable: false,
          value: 'created',
        },
        { text: 'Title', value: 'title' },
      ],
      displayNotificationDialog: false,
      selectedItem: {},
      newNotification: {
        title: '',
        body: '',
      },
      numberOfNotificationsPerPage: 1000,
    }
  },
  mounted() {},
  computed: {
    ...mapGetters([GET_NOTIFICATIONS, IS_NOTIFICATIONS_LOADING]),
    allNotifications() {
      return this[GET_NOTIFICATIONS].map((notification) => {
        notification.created = format(new Date(notification.created), DISPLAY_DATE_FORMAT)
        return notification
      })
    },
    notificationsLoading() {
      return this[IS_NOTIFICATIONS_LOADING]
    },
    createNotificationLoading() {
      return this[IS_CREATE_NOTIFICATION_LOADING]
    },
    selectedNotificationTitle() {
      return this.selectedItem.title
    },
    selectedNotificationBody() {
      return this.selectedItem.body
    },
    isFormValid() {
      return this.newNotification.title.length > 0 && this.newNotification.body.length > 0
    },
  },
  created() {
    this.$store.dispatch(LOAD_NOTIFICATIONS)
  },
  methods: {
    displayNotification(item) {
      console.log('row: ', item)
      this.selectedItem = item
      this.displayNotificationDialog = true
    },
    createNotification() {
      this.$store.dispatch(CREATE_NOTIFICATION, this.newNotification).then(() => {
        this.$store.dispatch(LOAD_NOTIFICATIONS)
        this.close()
      })
    },
    close() {
      this.displayNotificationDialog = false
      this.$nextTick(() => {
        this.selectedItem = {}
        this.newNotification = Object.assign({}, this.newNotificationTemplate)
      })
    },
  },
}
</script>

<style lang="scss" scoped></style>
