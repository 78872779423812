<template>
  <v-app>
    <v-app-bar app color="primary" dark>
      <div class="d-flex align-center padding-right" @click="toggleApp">
        <v-img v-if="app === 'rcd'" alt="Logo" class="shrink mr-2" contain src="@/assets/logo_white.png" transition="scale-transition" width="250" />
        <v-img v-else alt="Logo" class="shrink mr-2" contain src="@/assets/logo_rcm.png" transition="scale-transition" width="250" />
      </div>
      <v-tabs v-if="isLoggedIn">
        <v-tab :to="dedicationsRoute">Dedications</v-tab>
        <v-tab :to="usersRoute">Users</v-tab>
        <v-tab :to="messagesRoute">Messages</v-tab>
        <v-tab v-if="isRCD" :to="notificationsRoute">Notifications</v-tab>
        <v-tab :to="videosRoute">Videos</v-tab>
      </v-tabs>
      <v-spacer></v-spacer>
      <v-btn text v-if="isLoggedIn" @click="logout"> Logout </v-btn>
    </v-app-bar>

    <v-main>
      <router-view></router-view>
    </v-main>
  </v-app>
</template>

<script>
import { mapGetters } from 'vuex'
import {
  GET_IS_LOGGED_IN,
  LOGOUT,
  R_NAME_DEDICATIONS,
  R_NAME_HOME,
  R_NAME_MESSAGES,
  R_NAME_USERS,
  R_NAME_NOTIFICATIONS,
  R_NAME_VIDEOS,
  R_NAME_DEDICATIONS_RCM,
  R_NAME_MESSAGES_RCM,
} from '@/services/constants'

export default {
  name: 'App',
  metaInfo: {
    title: 'Real Clear Daf Admin',
  },
  data() {
    return {
      app: 'rcd',
    }
  },
  computed: {
    ...mapGetters([GET_IS_LOGGED_IN]),
    isRCD() {
      return this.app === 'rcd'
    },
    isLoggedIn() {
      return this[GET_IS_LOGGED_IN]
    },
    dedicationsRoute() {
      return this.isRCD ? { name: R_NAME_DEDICATIONS } : { name: R_NAME_DEDICATIONS_RCM }
    },
    usersRoute() {
      return { name: R_NAME_USERS }
    },
    messagesRoute() {
      return this.isRCD ? { name: R_NAME_MESSAGES } : { name: R_NAME_MESSAGES_RCM }
    },
    notificationsRoute() {
      return { name: R_NAME_NOTIFICATIONS }
    },
    videosRoute() {
      return { name: R_NAME_VIDEOS }
    },
  },
  methods: {
    logout() {
      this.$store.dispatch(LOGOUT)
      this.$router.push({ name: R_NAME_HOME })
    },
    toggleApp() {
      this.app = this.isRCD ? 'rcm' : 'rcd'
      if (!this.isRCD) {
        if (this.$route.name === R_NAME_DEDICATIONS) {
          this.$router.replace({ name: R_NAME_DEDICATIONS_RCM })
        } else if (this.$route.name === R_NAME_MESSAGES) {
          this.$router.replace({ name: R_NAME_MESSAGES_RCM })
        }
      } else {
        if (this.$route.name === R_NAME_DEDICATIONS_RCM) {
          this.$router.replace({ name: R_NAME_DEDICATIONS })
        } else if (this.$route.name === R_NAME_MESSAGES_RCM) {
          this.$router.replace({ name: R_NAME_MESSAGES })
        }
      }
    },
  },
}
</script>

<style scoped lang="scss">
.padding-right {
  padding-right: 30px;
  cursor: pointer;
}
</style>
