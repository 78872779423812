<template>
  <div>
    <router-view></router-view>
  </div>
</template>

<script>
export default {
  name: 'AppContainer',
}
</script>

<style>
@import 'scss/global.scss';
</style>
