<template>
  <div class="plyr-container">
    <vue-plyr ref="plyr" :options="options">
      <video controls crossorigin playsinline>
        <source v-if="!isHls" :src="src" type="video/mp4" />
      </video>
    </vue-plyr>
  </div>
</template>

<script>
import { mapGetters } from 'vuex'
import { GET_BACKEND_URL } from '@/services/constants'
import Hls from 'hls.js'
import axios from 'axios'

export default {
  name: 'RealcleardafadminPlayer',
  data() {
    return {
      options: {},
      isHls: false,
      src: '',
    }
  },
  computed: {
    ...mapGetters([GET_BACKEND_URL]),
    videoSrc() {
      const videokey = this.$route.params.videokey
      return this[GET_BACKEND_URL](`videos/${videokey}`)
    },
    player() {
      console.log(this.$refs.plyr, this.$refs.plyr.player)
      return this.$refs.plyr.player
    },
  },
  mounted() {
    this.init()
  },
  beforeDestroy() {
    const hls = window.hls
    hls.destroy()
    this.player.destroy()
  },
  methods: {
    init() {
      axios.get(this.videoSrc).then((response) => {
        const videoData = response.data
        this.isHls = Hls.isSupported() && !!videoData.streamUrl
        if (this.isHls) {
          const hls = new Hls()
          hls.loadSource(videoData.streamUrl)
          hls.attachMedia(this.player.media)
          window.hls = hls
        } else {
          this.src = videoData.videoUrl
        }
      })
    },
  },
}
</script>

<style scoped>
.plyr-container {
  display: flex;
  justify-content: center;
  width: 100vw;
  height: 100vh;
  box-sizing: content-box;
}

.plyr {
  width: 100%;
  height: 100%;
}
</style>
