import cookies from 'js-cookie'

const CookieService = {
  getByKey: (key) => {
    const v = cookies.get(key)
    return v ? v : null
  },
  set: (key, value) => {
    const v = cookies.set(key, value, { expires: 365 * 10 })
    return v ? v : null
  },

  removeByKey: (key) => {
    cookies.remove(key)
  },
}

export default CookieService
