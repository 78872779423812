<template>
  <div style="padding: 20px 25px">
    <v-data-table
      :headers="headers"
      :items="allVideos"
      :items-per-page="numberOfVideosPerPage"
      class="elevation-1"
      :loading="videosLoading"
      loading-text="Loading... Please wait"
      hide-default-footer
      @click:row="displayVideo"
    >
      <template #item.assetUrl="{ value }">
        <a :href="value">{{ value }}</a>
      </template>
      <template #item.playerUrl="{ value }">
        <a :href="value">{{ value }}</a>
      </template>
    </v-data-table>
    <div class="create-video">
      <v-row>
        <v-col cols="12" sm="4" md="4">
          <div class="headline lighten-2">Upload New Video</div>
        </v-col>
      </v-row>
      <v-row>
        <v-col cols="12" md="6" sm="6">
          <v-form ref="form" class="pa-2">
            <v-file-input v-model="videoFile" accept="video/*" label="Video File"></v-file-input>
          </v-form>
        </v-col>
      </v-row>
      <v-row>
        <v-col cols="12" sm="6" md="6" class="text-right">
          <v-btn text secondary @click="uploadVideo" :loading="videoUploadLoading" :disabled="!isFormValid">Upload</v-btn>
        </v-col>
      </v-row>
    </div>
  </div>
</template>

<script>
import { mapGetters } from 'vuex'
import { format } from 'date-fns'
import {
  GET_VIDEOS,
  IS_VIDEOS_LOADING,
  LOAD_VIDEOS,
  DISPLAY_DATE_FORMAT,
  CREATE_PRESIGNED_URL,
  UPLOAD_TO_S3,
  IS_LOADED_TO_S3_COMPLETE,
  GET_PRESIGNED_URL,
  CREATE_VIDEO,
  GET_PRESIGNED_KEY,
} from '@/services/constants'

export default {
  name: 'Videos',
  data() {
    return {
      headers: [
        {
          text: 'Date',
          align: 'start',
          sortable: false,
          value: 'created',
        },
        { text: 'File Name', value: 'filename' },
        { text: 'Asset URL', value: 'assetUrl' },
        { text: 'Player URL', value: 'playerUrl' },
      ],
      displayVideoDialog: false,
      selectedItem: {},
      newVideo: {
        title: '',
        body: '',
      },
      numberOfVideosPerPage: 1000,
      videoFile: null,
      videoUploadLoading: false,
    }
  },
  mounted() {},
  computed: {
    ...mapGetters([GET_VIDEOS, IS_VIDEOS_LOADING, GET_PRESIGNED_URL, IS_LOADED_TO_S3_COMPLETE, GET_PRESIGNED_KEY]),
    allVideos() {
      return this[GET_VIDEOS].map((video) => {
        video.created = format(new Date(video.created), DISPLAY_DATE_FORMAT)
        return video
      })
    },
    videosLoading() {
      return this[IS_VIDEOS_LOADING]
    },
    selectedVideoTitle() {
      return this.selectedItem.title
    },
    selectedVideoBody() {
      return this.selectedItem.body
    },
    isFormValid() {
      return this.videoFile && !this.videoUploadLoading
    },
    presignedObjectPayload() {
      return {
        filename: this.videoFile.name,
        contentType: this.videoFile.type,
        size: this.videoFile.size,
      }
    },
    uploadToS3Payload() {
      return {
        url: this[GET_PRESIGNED_URL],
        file: this.videoFile,
      }
    },
  },
  created() {
    this.$store.dispatch(LOAD_VIDEOS)
  },
  methods: {
    displayVideo(item) {
      console.log('row: ', item)
      this.selectedItem = item
      this.displayVideoDialog = true
    },
    uploadVideo() {
      this.videoUploadLoading = true
      this.$store
        .dispatch(CREATE_PRESIGNED_URL, this.presignedObjectPayload)
        .then(() => {
          return this.$store.dispatch(UPLOAD_TO_S3, this.uploadToS3Payload)
        })
        .then(() => {
          return this.$store.dispatch(CREATE_VIDEO, {
            file: this[GET_PRESIGNED_KEY],
          })
        })
        .then(() => {
          if (!this[IS_LOADED_TO_S3_COMPLETE]) {
            throw new Error()
          }
          this.videoFile = null
          this.$store.dispatch(LOAD_VIDEOS)
          this.videoUploadLoading = false
        })
    },
  },
}
</script>

<style lang="scss" scoped></style>
