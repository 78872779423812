import Vue from 'vue'
import Router from 'vue-router'
import store from './store'

import Home from './views/Home.vue'
import AuthenticatedContainer from './views/AuthenticatedContainer'

import {
  GET_IS_LOGGED_IN,
  R_NAME_HOME,
  R_NAME_DASHBOARD,
  R_NAME_DEDICATIONS,
  R_NAME_USERS,
  R_NAME_MESSAGES,
  R_NAME_NOTIFICATIONS,
  R_NAME_PLAYER,
  R_NAME_VIDEOS,
  R_NAME_MESSAGES_RCM,
  R_NAME_DEDICATIONS_RCM,
} from './services/constants'
import Dashboard from './views/Dashboard'
import Dedications from './components/Dedications'
import Users from './components/Users'
import Messages from './components/Messages'
import Notifications from './components/Notifications'
import App from './App'
import Player from './components/Player'
import Videos from './components/Videos'

Vue.use(Router)

export default new Router({
  mode: 'history',
  base: process.env.BASE_URL,
  routes: [
    {
      path: '/admin',
      component: App,
      redirect: { name: R_NAME_DEDICATIONS },
      children: [
        {
          path: 'login',
          name: R_NAME_HOME,
          component: Home,
        },
        {
          path: 'u',
          component: AuthenticatedContainer,
          beforeEnter: (to, from, next) => {
            loginGuard(to, from, next)
          },
          children: [
            {
              path: 'dashboard',
              name: R_NAME_DASHBOARD,
              component: Dashboard,
              children: [
                {
                  path: 'dedications',
                  name: R_NAME_DEDICATIONS,
                  component: Dedications,
                },
                {
                  path: 'dedications/rcm',
                  name: R_NAME_DEDICATIONS_RCM,
                  component: Dedications,
                  props: {
                    app: 'rcm',
                  },
                },
                {
                  path: 'users',
                  name: R_NAME_USERS,
                  component: Users,
                },
                {
                  path: 'messages',
                  name: R_NAME_MESSAGES,
                  component: Messages,
                },
                {
                  path: 'messages/rcm',
                  name: R_NAME_MESSAGES_RCM,
                  component: Messages,
                  props: {
                    app: 'rcm',
                  },
                },
                {
                  path: 'notifications',
                  name: R_NAME_NOTIFICATIONS,
                  component: Notifications,
                },
                {
                  path: 'videos',
                  name: R_NAME_VIDEOS,
                  component: Videos,
                },
              ],
            },
          ],
        },
      ],
    },
    { path: '/player/video/:videokey', name: R_NAME_PLAYER, component: Player },
  ],
})

const loginGuard = (to, from, next) => {
  if (store.getters[GET_IS_LOGGED_IN]) {
    next()
  } else {
    next({
      name: R_NAME_HOME,
    })
  }
}
